<template>
<div id="container" v-if="products">

  <ClosedAlert v-if="closed" />
<div class="heading">
  <img class="image" src="https://imageproxy.wolt.com/venue/641b62ba5d45039a7677ebab/540e7e60-ca87-11ed-a0b9-4ad37efdfd9b_rohuaia_kohvik3.jpg?w=1920"/>
  <div class="text"> 
    <h1>Rohuaia Kohvik</h1>
  </div>
  
</div>

  <div v-if="products" class="content">
  <AddCart 
  ref="addDialog"
  
  @changeDialog="changeDialog" 
  @changeAmount="changeDialogAmount"
  @changeOption="changeOption"
  @changeExtras="changeExtras"
  @changeNote="changeNote"
  @addToCart="addToCart"
  :dialog.sync="dialog" 
  :addCartId="addCartId"
   :cart="cart" 
   :unProducts="unProducts"
   :amount="dialogAmount"
   :selectedOption="dialogOption"
   :selectedExtras="dialogExtras"
   :note.sync="dialogNote"
   />
  
  <CartCheckout 
  @changeDialog="changeCartDialog" 
  @changeNote="changeCartNote"
  @setPlace="changeAddress"
  @changeDeliveryDistance="changeDeliveryDistance"
  @removeCart="removeCart"
  :note="dialogNote"
  :closed="closed"
  :dialog.sync="cartDialog" 
  :cart="cart" 
  :getProductData="getProductData"
  :currentPlace.sync="deliveryAddress"
  :deliveryDistance.sync="deliveryDistance"
  :rates="rates"
  :getTotal="getTotal"/>
  <div id="space"></div>
  <div id="categories">
  <Categories :cats="getCategories()" @tabChange="tabChange" :tab.sync="tab"/>
  </div>
  <div id="box">
    <SideCategories
    @tabChange="tabChange"
    @openCart="changeCartDialog(true)"
    :cats="getCategories()"
    :tab.sync="tab"
    :getCartTotalCount="getCartTotalCount"
      />
    <div id="product_list">
      <ProductList
       :products="products"
       :tab.sync="tab"
       :cart.sync="cart"
       @addCartDialog="addCartDialog"
       />
    </div>
    <div id="large_cart">
      <LargeCart
      @removeCart="removeCart"
      @changeCartDialog="changeCartDialog"
      :cart="cart" 
      :getProductData="getProductData"
      :getTotal="getTotal"
      :note="cartNote"
      :getCartTotalCount="getCartTotalCount"
      
   />
    </div>
    </div>
  </div>

  <CheckoutBtn :getTotal="getTotal" :getCartTotalCount="getCartTotalCount" @openCart="changeCartDialog(true)"/>
</div>
</template>

<script>
import ProductList from "../components/HomeView/ProductList";
import Categories from "../components/HomeView/Categories.vue";
import SideCategories from "../components/HomeView/SideCategories.vue";
import AddCart from '../components/HomeView/AddCart.vue';
import CheckoutBtn from '../components/HomeView/Extras/CheckoutBtn.vue';
import CartCheckout from '../components/HomeView/CartCheckout.vue';
import LargeCart from '../components/HomeView/LargeCart.vue';
import ClosedAlert from '../components/HomeView/Extras/ClosedAlert.vue';
import axios from "axios";


export default {
  name: "HomeView",

  components: {
    ProductList,
    Categories,
    SideCategories,
    AddCart,
    CheckoutBtn,
    CartCheckout,
    LargeCart, 
    ClosedAlert
  },
  data: function() {
    return {
      loading: null,
      closed: false,
      ctab:null,
      products: {},
      unProducts: [],
      rates: {},
      tab: 0,
      cart: {},
      dialog: false,
      addCartId: null,
      dialogAmount: 1,
      dialogOption: "0",
      dialogExtras: [],
      dialogNote: "",
      cartNote: "",
      cartDialog: false,
      deliveryAddress: {},
      deliveryDistance: 0
    }
  },

  beforeMount() {
    let loader = this.$loading.show({
    });
    const current_time = new Date(Date.now() + 10800)
    // TODO test new times
    if (current_time.getHours() >= 20 & current_time.getMinutes() > 40) {
      this.closed = true;
    } else if (current_time.getHours() <= 10 & current_time.getMinutes() < 30) {
      this.closed = true;
    }

      axios.get(process.env.VUE_APP_API + "products")
        .then(response => {
          this.products = response.data.ordered
          this.unProducts = response.data.unordered
          this.addCartId = this.unProducts[0].id
          this.rates = response.data.rates

          loader.hide()
                if (this.$route.query.load_cart) {
        if (localStorage.getItem("cart")) {
          this.cart = JSON.parse(localStorage.getItem("cart"))
          loader.hide()
        }
      } 
        })
        .catch(error => {
          document.location.reload()
        })


},
  methods: {
    removeCart(id, index) {
      this.cart[id].splice(index, 1)
      this.$set(this.cart, id, this.cart[id])
    },
    changeDeliveryDistance(e) {
      this.deliveryDistance = e
      console.log(this.deliveryDistance)
    },
    changeAddress(e) {
      this.deliveryAddress = e;
    },
    changeCartNote(note){
      this.cartNote = note
    },
    getCartTotalCount() {
      let count = 0;
      for (let i = 0; i < Object.keys(this.cart).length; i++) {
        for (let ic = 0; ic < this.cart[Object.keys(this.cart)[i]].length; ic++) {
          count += this.cart[Object.keys(this.cart)[i]][ic].amount
        }
      }
      return count;
    },
    getTotal() {
      let totalPrice = 0.0;

      for (let i = 0; i < Object.keys(this.cart).length; i++) {
        for (let ic = 0; ic < this.cart[Object.keys(this.cart)[i]].length; ic++) {
          let currentTotalPrice = 0.0
          let currentCartItem = this.cart[Object.keys(this.cart)[i]][ic]
          let productId = Object.keys(this.cart)[i]
          let currentProduct = this.getProductData(productId)

          if (currentCartItem.option == Object.keys(currentProduct.options)[0]) {
            currentTotalPrice += currentProduct.price
          } else {
            currentTotalPrice += currentProduct.options[currentCartItem.option]
          }
          for (let ie = 0; ie < currentCartItem.extras.length; ie++) {

            currentTotalPrice += currentProduct.extras[currentCartItem.extras[ie]]

          }


          currentTotalPrice = currentTotalPrice * currentCartItem.amount
          totalPrice += currentTotalPrice
        }

      }
      return Math.round(totalPrice * 100) / 100;
    },
    addToCart(e) {
      if (this.cart[this.addCartId]) {
        let newList = this.cart[this.addCartId]
        newList.push({
          "amount":this.dialogAmount,
          "option":this.dialogOption,
          "extras": this.dialogExtras,
          "note":this.dialogNote,
          "id":Math.floor(Math.random() * 10000)})

        this.$set(this.cart, this.addCartId, newList)
      } else {
        this.$set(this.cart, this.addCartId,[{"amount":this.dialogAmount, "option":this.dialogOption, "extras": this.dialogExtras, "note":this.dialogNote, "id":Math.floor(Math.random() * 10000)}])
      }
      this.changeDialog(false)
      




    },
    changeCartDialog(e) {
      this.cartDialog = e
    },
    changeNote(e) {
      this.dialogNote = e
    },
    changeExtras(e) {
      this.dialogExtras = e
    },
    changeOption(e) {
      this.dialogOption = e
    },
    tabChange(e) {
      this.tab = e
    },
    getCategories() {
      return Object.keys(this.products)

    },
    changeDialog(e) {
      this.dialog = e
      
    },
    addCartDialog(e) {
      this.addCartId = e
      this.dialogOption = Object.keys(this.getProductData(e).options)[0]
      this.dialogExtras = []
      this.dialogNote = ""
      this.dialogAmount = 1
      this.changeDialog(true)
      
      
    },
    changeDialogAmount(num) {
      this.dialogAmount += num
    },
    getProductData(id) {
            for (let i = 0; i < this.unProducts.length; i++) {
                if (this.unProducts[i].id == id) {
                    return this.unProducts[i]
                }
        }
        },
  }
};
</script>
<style scoped>
.heading {

}

.text {
  width: 100%;
  height: 300px;
  position: absolute;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.text h1 {
  font-size: 3em;
  color: white;
  text-align: center;
  padding: 10px;
}
.image {
  width: 100%;
  object-fit: cover;
  height: 300px;
  filter: brightness(0.5);
}
#space {
  height: 50px;
}
#product_list {
  display: flex;
  justify-content: center;
}


#container {
  width: 100%;
}

#box {
  display:flex;
  justify-content: center;
}


</style>
